import React from 'react';
import { LoadingSpinner } from './LoadingSpinner';
import { Download } from 'lucide-react';
import { doc, runTransaction } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import toast from 'react-hot-toast';

interface ImagePreviewProps {
  imageUrl: string;
  isLoading: boolean;
}

export function ImagePreview({ imageUrl, isLoading }: ImagePreviewProps) {
  const handleDownload = async () => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `coloring-page-${Date.now()}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
      toast.error('Failed to download image');
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[400px] bg-white rounded-xl p-8">
        <LoadingSpinner />
      </div>
    );
  }

  if (!imageUrl) {
    return null;
  }

  return (
    <div className="space-y-6">
      {/* Image Container */}
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="relative aspect-square">
          <img 
            src={imageUrl} 
            alt="Generated coloring page" 
            className="w-full h-full object-contain"
          />
        </div>
      </div>

      {/* Download Button */}
      <div className="flex justify-center">
        <button
          onClick={handleDownload}
          className="flex items-center gap-2 px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg transition duration-200 shadow-md hover:shadow-lg"
        >
          <Download className="w-5 h-5" />
          Download PNG
        </button>
      </div>
    </div>
  );
}
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Users, Settings, Shield, CreditCard } from 'lucide-react';

export default function AdminPortal() {
  const navigate = useNavigate();

  const adminTools = [
    {
      title: 'User Management',
      description: 'Manage users, roles, and accounts',
      icon: Users,
      path: '/admin/users',
      color: 'blue'
    },
    {
      title: 'Credit Management',
      description: 'Manage user credits and track history',
      icon: CreditCard,
      path: '/admin/credits',
      color: 'green'
    },
    {
      title: 'System Settings',
      description: 'Configure system parameters and limits',
      icon: Settings,
      path: '/admin/settings',
      color: 'purple'
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center gap-3 mb-8">
          <Shield className="w-8 h-8 text-gray-700" />
          <h1 className="text-2xl font-bold text-gray-900">Admin Portal</h1>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {adminTools.map((tool) => {
            const Icon = tool.icon;
            return (
              <button
                key={tool.path}
                onClick={() => navigate(tool.path)}
                className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-all duration-200 text-left group"
              >
                <div className="flex items-start gap-4">
                  <div className={`p-3 bg-${tool.color}-50 rounded-lg group-hover:scale-110 transition-transform`}>
                    <Icon className={`w-6 h-6 text-${tool.color}-600`} />
                  </div>
                  <div>
                    <h2 className="text-xl font-semibold text-gray-900 mb-2">
                      {tool.title}
                    </h2>
                    <p className="text-gray-600">{tool.description}</p>
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}
import React, { useState } from 'react';
import { Sparkles } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { DoodleAnythingForm } from '../../components/DoodleAnythingForm';
import { ImagePreview } from '../../components/ImagePreview';

function DoodleAnything() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Doodle Anything"
      description="Create charming patterns with cute doodles!"
      icon={<Sparkles className="w-10 h-10 text-yellow-500" />}
    >
      <div className="p-6 space-y-8">
        <DoodleAnythingForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default DoodleAnything;
import React, { useState } from 'react';
import { Sparkles } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface DoodleAnythingFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

export function DoodleAnythingForm({ onImageGenerated, setIsLoading }: DoodleAnythingFormProps) {
  const [subject, setSubject] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!subject.trim()) {
      toast.error('Please enter a subject');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `A lot of white ${subject} ultra-simple wallpaper, all image of seamless doodle cute creatures in thin black line drawing forming a charming pattern, ultra-minimalistic design. 2D sketch, perfect drawing, happy faces. various shapes.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
            What would you like to doodle today?
          </label>
          <input
            id="subject"
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="e.g., cats, dinosaurs, space aliens, unicorns"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
            required
          />
          <p className="mt-1 text-sm text-gray-500">
            Try cute animals, magical creatures, or anything fun!
          </p>
        </div>

        <button
          type="submit"
          className="w-full bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Sparkles className="w-5 h-5" />
          Generate Cute Doodles
        </button>
      </form>
    </>
  );
}
import React, { useState } from 'react';
import { Copyright } from 'lucide-react';
import { LoadingOverlay } from '../LoadingOverlay';
import toast from 'react-hot-toast';

interface CopyrightPageFormProps {
  onCopyrightGenerated: (text: string) => void;
  setIsLoading: (loading: boolean) => void;
}

export function CopyrightPageForm({ onCopyrightGenerated, setIsLoading }: CopyrightPageFormProps) {
  const [bookTitle, setBookTitle] = useState('');
  const [authorName, setAuthorName] = useState('');
  const [isbn, setIsbn] = useState('');
  const [publisher, setPublisher] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);

  const handleGenerate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!bookTitle.trim() || !authorName.trim()) {
      toast.error('Please enter both book title and author name');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Act as a copyright expert. Generate a copyright page for a book titled "${bookTitle}" by ${authorName}${isbn ? ` with ISBN ${isbn}` : ''}${publisher ? ` published by ${publisher}` : ''}. Include:
1. Copyright notice (© ${new Date().getFullYear()} ${authorName})
2. All rights reserved statement
3. ISBN number (if provided)
4. Publisher information (if provided)
5. Standard disclaimer
6. Additional legal information`;
      
      const response = await fetch(`${import.meta.env.VITE_AZURE_OPENAI_ENDPOINT}/openai/deployments/gpt-4o-mini/chat/completions?api-version=2024-02-15-preview`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': import.meta.env.VITE_AZURE_OPENAI_KEY,
        },
        body: JSON.stringify({
          messages: [{ role: 'user', content: prompt }],
          max_tokens: 2000,
          temperature: 0.2,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate copyright page');
      }

      const data = await response.json();
      onCopyrightGenerated(data.choices[0].message.content);
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate copyright page');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerate} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="bookTitle" className="block text-sm font-medium text-gray-700 mb-1">
              Book Title
            </label>
            <input
              id="bookTitle"
              type="text"
              value={bookTitle}
              onChange={(e) => setBookTitle(e.target.value)}
              placeholder="Enter book title"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
              required
            />
          </div>

          <div>
            <label htmlFor="authorName" className="block text-sm font-medium text-gray-700 mb-1">
              Author Name
            </label>
            <input
              id="authorName"
              type="text"
              value={authorName}
              onChange={(e) => setAuthorName(e.target.value)}
              placeholder="Enter author name"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
              required
            />
          </div>

          <div>
            <label htmlFor="isbn" className="block text-sm font-medium text-gray-700 mb-1">
              ISBN (optional)
            </label>
            <input
              id="isbn"
              type="text"
              value={isbn}
              onChange={(e) => setIsbn(e.target.value)}
              placeholder="Enter ISBN"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
            />
          </div>

          <div>
            <label htmlFor="publisher" className="block text-sm font-medium text-gray-700 mb-1">
              Publisher (optional)
            </label>
            <input
              id="publisher"
              type="text"
              value={publisher}
              onChange={(e) => setPublisher(e.target.value)}
              placeholder="Enter publisher name"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
            />
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-yellow-600 hover:bg-yellow-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Copyright className="w-5 h-5" />
          Generate Copyright Page
        </button>
      </form>
    </>
  );
}
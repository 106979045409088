import React, { useState } from 'react';
import { Users } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface QuarrelSceneFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const BACKGROUND_SUGGESTIONS = [
  'restaurant kitchen',
  'art gallery',
  'library',
  'garden',
  'movie theater',
  'coffee shop',
  'board game cafe',
  'music studio'
] as const;

export function QuarrelSceneForm({ onImageGenerated, setIsLoading }: QuarrelSceneFormProps) {
  const [background, setBackground] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!background.trim()) {
      toast.error('Please select a background');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a clean and simple illustration for a coloring page featuring a random two person, in a quarrel scene with setting at the ${background}. Fight about something related to ${background}. The design should be in black-and-white line art, suitable for coloring, with clear outlines and fun details.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="background" className="block text-sm font-medium text-gray-700 mb-1">
            Where is the disagreement happening?
          </label>
          <input
            id="background"
            type="text"
            value={background}
            onChange={(e) => setBackground(e.target.value)}
            placeholder="e.g., restaurant kitchen, art gallery"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
            required
          />
          
          <div className="mt-3">
            <p className="text-sm text-gray-600 mb-2">Quick suggestions:</p>
            <div className="flex flex-wrap gap-2">
              {BACKGROUND_SUGGESTIONS.map((suggestion) => (
                <button
                  key={suggestion}
                  type="button"
                  onClick={() => setBackground(suggestion)}
                  className="px-3 py-1 text-sm bg-orange-50 text-orange-700 rounded-full hover:bg-orange-100 transition-colors"
                >
                  {suggestion}
                </button>
              ))}
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-orange-600 hover:bg-orange-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Users className="w-5 h-5" />
          Generate Quarrel Scene
        </button>
      </form>
    </>
  );
}
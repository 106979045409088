import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../lib/firebase';
import { deductCredit } from './useCredits';
import { generateImage } from '../utils/azure';
import toast from 'react-hot-toast';

export function useImageGeneration() {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const generateWithCredits = async (prompt: string): Promise<string | null> => {
    if (!user) {
      toast.error('Please sign in to generate images');
      navigate('/login');
      return null;
    }

    setIsLoading(true);
    try {
      // Check credits before generating
      const hasCredits = await deductCredit();
      if (!hasCredits) {
        throw new Error('Insufficient credits. Please upgrade your plan or purchase more credits.');
      }

      const imageUrl = await generateImage(prompt);
      if (!imageUrl) {
        throw new Error('Failed to generate image');
      }

      toast.success('Image generated successfully!');
      return imageUrl;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to generate image';
      console.error('Error generating image:', errorMessage);
      toast.error(errorMessage);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    generateWithCredits,
    isLoading,
    setIsLoading
  };
}
import React from 'react';
import { Outlet } from 'react-router-dom';
import { CategoryLayout } from '../../components/layout/CategoryLayout';
import { Lightbulb, Palette, BookOpen, Copyright } from 'lucide-react';

const tools = [
  {
    id: 'coloring-ideas',
    title: 'Coloring Ideas',
    description: 'Generate creative coloring page themes',
    icon: Palette,
    path: 'coloring-ideas'
  },
  {
    id: 'amazon-description',
    title: 'Amazon Description',
    description: 'Generate optimized book descriptions',
    icon: BookOpen,
    path: 'amazon-description'
  },
  {
    id: 'copyright-page',
    title: 'Copyright Page',
    description: 'Generate professional copyright pages',
    icon: Copyright,
    path: 'copyright-page'
  }
];

function ContentLayout() {
  return (
    <CategoryLayout
      title="Content Ideas"
      description="AI-powered coloring inspiration and planning"
      icon={Lightbulb}
      color="yellow"
      tools={tools}
    >
      <Outlet />
    </CategoryLayout>
  );
}

export default ContentLayout;
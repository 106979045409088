import React, { useState } from 'react';
import { BookOpen } from 'lucide-react';
import { LoadingOverlay } from '../LoadingOverlay';
import toast from 'react-hot-toast';

interface AmazonDescriptionFormProps {
  onDescriptionGenerated: (description: any) => void;
  setIsLoading: (loading: boolean) => void;
}

export function AmazonDescriptionForm({ onDescriptionGenerated, setIsLoading }: AmazonDescriptionFormProps) {
  const [bookTitle, setBookTitle] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);

  const handleGenerate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!bookTitle.trim()) {
      toast.error('Please enter a book title');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `You are a Chief Marketing Officer crafting a product description for a book titled "${bookTitle}". Create a compelling Amazon product detail page that includes:

1. A persuasive main description
2. 5 key bullet points highlighting benefits
3. A compelling call to action
4. 7 long-tail and 7 short-tail SEO keywords (excluding author names and book titles)
5. Suggested Amazon categories

Format the response with clear sections.`;
      
      const response = await fetch(`${import.meta.env.VITE_AZURE_OPENAI_ENDPOINT}/openai/deployments/gpt-4o-mini/chat/completions?api-version=2024-02-15-preview`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': import.meta.env.VITE_AZURE_OPENAI_KEY,
        },
        body: JSON.stringify({
          messages: [{ role: 'user', content: prompt }],
          max_tokens: 2000,
          temperature: 0.2,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate description');
      }

      const data = await response.json();
      const content = data.choices[0].message.content;

      // Parse the content into sections
      const sections = content.split('\n\n');
      const description = {
        title: bookTitle,
        description: sections[0],
        bulletPoints: sections[1].split('\n'),
        callToAction: sections[2],
        keywords: sections[3].split('\n').slice(1),
        categories: sections[4].split('\n').slice(1),
      };

      onDescriptionGenerated(description);
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate description');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerate} className="space-y-6">
        <div>
          <label htmlFor="bookTitle" className="block text-sm font-medium text-gray-700 mb-1">
            Enter your book title
          </label>
          <input
            id="bookTitle"
            type="text"
            value={bookTitle}
            onChange={(e) => setBookTitle(e.target.value)}
            placeholder="e.g., The Art of Mindful Coloring"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
            required
          />
        </div>

        <button
          type="submit"
          className="w-full bg-yellow-600 hover:bg-yellow-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <BookOpen className="w-5 h-5" />
          Generate Description
        </button>
      </form>
    </>
  );
}
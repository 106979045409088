import React, { useState } from 'react';
import { Globe2 } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface MiniatureWorldFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const CONTAINER_OPTIONS = [
  { id: 'glass-jar', label: 'Glass Jar' },
  { id: 'snow-globe', label: 'Snow Globe' },
  { id: 'milk-bottle', label: 'Milk Bottle' },
  { id: 'light-bulb', label: 'Light Bulb' },
  { id: 'glass-ornament', label: 'Glass Ornament' },
  { id: 'bowl', label: 'Bowl' }
] as const;

export function MiniatureWorldForm({ onImageGenerated, setIsLoading }: MiniatureWorldFormProps) {
  const [subject, setSubject] = useState('');
  const [container, setContainer] = useState<string>(CONTAINER_OPTIONS[0].id);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!subject.trim()) {
      toast.error('Please enter a subject');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const selectedContainer = CONTAINER_OPTIONS.find(c => c.id === container)?.label || container;
      const prompt = `A Simple coloring book of a ${subject}, in a ${selectedContainer} miniature, peaceful, enchanting, and dreamy mood, simple line art, coloring book style, easy-to-follow lines for coloring, Minimalist, Black and white line art, clean lines, simple details, white backdrop.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
            What would you like in your miniature world?
          </label>
          <input
            id="subject"
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="e.g., fairy garden, tiny castle, magical forest"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Choose your container
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {CONTAINER_OPTIONS.map((option) => (
              <button
                key={option.id}
                type="button"
                onClick={() => setContainer(option.id)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  container === option.id
                    ? 'bg-violet-100 text-violet-700 border-2 border-violet-500'
                    : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                }`}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-violet-600 hover:bg-violet-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Globe2 className="w-5 h-5" />
          Generate Miniature World
        </button>
      </form>
    </>
  );
}
import React, { useState } from 'react';
import { Tent } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface TravelCampingFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const LOCATION_SUGGESTIONS = [
  'mountain peak',
  'forest clearing',
  'lakeside',
  'beach',
  'desert oasis',
  'meadow',
  'riverside',
  'national park'
] as const;

const BACKGROUND_ELEMENTS = [
  'campfire and tent',
  'hiking gear',
  'fishing equipment',
  'starry night sky',
  'wildlife watching',
  'picnic setup',
  'hammock between trees',
  'camping van'
] as const;

export function TravelCampingForm({ onImageGenerated, setIsLoading }: TravelCampingFormProps) {
  const [location, setLocation] = useState('');
  const [background, setBackground] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!location.trim() || !background.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a clean and simple illustration for a coloring page featuring a random person, in a cozy setting with travel or camping scene at the ${location} with the ${background}. The design should be in black-and-white line art, suitable for coloring, with clear outlines and fun details.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div className="space-y-4">
          <div>
            <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
              Choose a location
            </label>
            <input
              id="location"
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="e.g., mountain peak, forest clearing"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
              required
            />
            
            <div className="mt-2">
              <div className="flex flex-wrap gap-2">
                {LOCATION_SUGGESTIONS.map((suggestion) => (
                  <button
                    key={suggestion}
                    type="button"
                    onClick={() => setLocation(suggestion)}
                    className="px-3 py-1 text-sm bg-emerald-50 text-emerald-700 rounded-full hover:bg-emerald-100 transition-colors"
                  >
                    {suggestion}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div>
            <label htmlFor="background" className="block text-sm font-medium text-gray-700 mb-1">
              Add background elements
            </label>
            <input
              id="background"
              type="text"
              value={background}
              onChange={(e) => setBackground(e.target.value)}
              placeholder="e.g., campfire and tent, hiking gear"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
              required
            />
            
            <div className="mt-2">
              <div className="flex flex-wrap gap-2">
                {BACKGROUND_ELEMENTS.map((element) => (
                  <button
                    key={element}
                    type="button"
                    onClick={() => setBackground(element)}
                    className="px-3 py-1 text-sm bg-emerald-50 text-emerald-700 rounded-full hover:bg-emerald-100 transition-colors"
                  >
                    {element}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-emerald-600 hover:bg-emerald-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Tent className="w-5 h-5" />
          Generate Adventure Scene
        </button>
      </form>
    </>
  );
}
import React from 'react';
import { Outlet } from 'react-router-dom';
import { CategoryLayout } from '../../components/layout/CategoryLayout';
import { 
  Palette, Ghost, Car, Cookie, Laugh, Bird, Zap 
} from 'lucide-react';

const tools = [
  {
    id: 'simple',
    title: 'Simple & Easy',
    description: 'Create simple coloring pages',
    icon: Palette,
    path: 'simple'
  },
  {
    id: 'superhero-name',
    title: 'Superhero Name',
    description: 'Create personalized superhero coloring pages',
    icon: Zap,
    path: 'superhero-name'
  },
  {
    id: 'monster-fun',
    title: 'Monster Fun',
    description: 'Create whimsical monster scenes',
    icon: Ghost,
    path: 'monster-fun'
  },
  {
    id: 'happy-vehicles',
    title: 'Happy Vehicles',
    description: 'Design adorable vehicle characters',
    icon: Car,
    path: 'happy-vehicles'
  },
  {
    id: 'food-coloring',
    title: 'Cute Food',
    description: 'Create adorable food characters',
    icon: Cookie,
    path: 'food-coloring'
  },
  {
    id: 'circus-clowns',
    title: 'Circus Clowns',
    description: 'Design fun circus scenes',
    icon: Laugh,
    path: 'circus-clowns'
  },
  {
    id: 'flying-birds',
    title: 'Flying Birds',
    description: 'Create soaring bird scenes',
    icon: Bird,
    path: 'flying-birds'
  }
];

function KidsLayout() {
  return (
    <CategoryLayout
      title="Kids Corner"
      description="Simple and fun designs perfect for little ones"
      icon={Palette}
      color="blue"
      tools={tools}
    >
      <Outlet />
    </CategoryLayout>
  );
}

export default KidsLayout;
import { auth, db } from '../lib/firebase';
import { doc, runTransaction, serverTimestamp } from 'firebase/firestore';
import toast from 'react-hot-toast';

export async function deductCredit(): Promise<boolean> {
  const user = auth.currentUser;
  if (!user) {
    toast.error('Please sign in to generate images');
    return false;
  }

  try {
    let success = false;
    const userRef = doc(db, 'users', user.uid);

    await runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userRef);

      if (!userDoc.exists()) {
        throw new Error('User account not found');
      }

      const userData = userDoc.data();

      // Admin users bypass credit check
      if (userData.role === 'admin') {
        success = true;
        return;
      }

      // Check available credits
      const currentCredits = userData.creditsAvailable || 0;
      if (currentCredits <= 0) {
        throw new Error('No credits available. Please upgrade your plan or purchase more credits.');
      }

      // Handle subscription users
      if (userData.role === 'subscription') {
        const now = new Date();
        const renewalDate = userData.subscriptionRenewalDate ? new Date(userData.subscriptionRenewalDate) : null;

        if (renewalDate && now >= renewalDate) {
          // Reset credits on renewal
          transaction.update(userRef, {
            creditsAvailable: 300,
            subscriptionRenewalDate: new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000).toISOString(),
            updatedAt: serverTimestamp()
          });
          success = true;
          return;
        }
      }

      // Deduct credit
      transaction.update(userRef, {
        creditsAvailable: currentCredits - 1,
        lastImageGenerated: serverTimestamp(),
        totalImagesGenerated: (userData.totalImagesGenerated || 0) + 1,
        updatedAt: serverTimestamp()
      });

      success = true;
    });

    return success;

  } catch (error) {
    console.error('Credit deduction failed:', error);
    const errorMessage = error instanceof Error ? error.message : 'Failed to process credits';
    toast.error(errorMessage);
    return false;
  }
}
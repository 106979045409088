import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CreditGuard } from '../components/CreditGuard';
import { Palette, Heart, PenTool, Brain, Lightbulb } from 'lucide-react';

const categories = [
  {
    id: 'kids',
    title: 'Kids Corner',
    description: 'Simple and fun designs perfect for little ones',
    icon: Palette,
    color: 'blue',
    tools: [
      { id: 'simple', title: 'Simple & Easy', description: 'Create simple coloring pages', path: '/kids/simple' },
      { id: 'superhero-name', title: 'Superhero Name', description: 'Create personalized superhero coloring pages', path: '/kids/superhero-name' },
      { id: 'monster-fun', title: 'Monster Fun', description: 'Create whimsical monster scenes', path: '/kids/monster-fun' },
      { id: 'happy-vehicles', title: 'Happy Vehicles', description: 'Design adorable vehicle characters', path: '/kids/happy-vehicles' },
      { id: 'food-coloring', title: 'Food Coloring', description: 'Create adorable food characters', path: '/kids/food-coloring' },
      { id: 'circus-clowns', title: 'Circus Clowns', description: 'Design fun circus scenes', path: '/kids/circus-clowns' },
      { id: 'flying-birds', title: 'Flying Birds', description: 'Create soaring bird scenes', path: '/kids/flying-birds' }
    ]
  },
  {
    id: 'adult',
    title: 'Adult Coloring',
    description: 'Complex and detailed designs for adults',
    icon: Brain,
    color: 'purple',
    tools: [
      { id: 'mandala', title: 'Mandala Art', description: 'Create intricate mandala designs', path: '/adult/mandala' },
      { id: 'tarot-cards', title: 'Tarot Cards', description: 'Design mystical tarot card illustrations', path: '/adult/tarot-cards' },
      { id: 'miniature-world', title: 'Miniature World', description: 'Create enchanting miniature scenes', path: '/adult/miniature-world' },
      { id: 'mystery-characters', title: 'Mystery Characters', description: 'Design enigmatic character scenes', path: '/adult/mystery-characters' },
      { id: 'quarrel-scene', title: 'Quarrel Scene', description: 'Create amusing disagreement scenes', path: '/adult/quarrel-scene' }
    ]
  },
  {
    id: 'cozy',
    title: 'Cozy Collection',
    description: 'Warm and inviting scenes for all ages',
    icon: Heart,
    color: 'red',
    tools: [
      { id: 'friends', title: 'Cozy Friends', description: 'Design heartwarming animal friendship scenes', path: '/cozy/friends' },
      { id: 'building', title: 'Cozy Building', description: 'Generate cozy architectural designs', path: '/cozy/building' },
      { id: 'travel-camping', title: 'Travel & Camping', description: 'Create outdoor adventure scenes', path: '/cozy/travel-camping' },
      { id: 'funny-shop', title: 'Scene at Shop', description: 'Design delightful shopping scenes', path: '/cozy/funny-shop' },
      { id: 'animal-profession', title: 'Animal Profession', description: 'Create professional animal characters', path: '/cozy/animal-profession' },
      { id: 'room-corner', title: 'Room Corner', description: 'Design isometric room corners', path: '/cozy/room-corner' }
    ]
  },
  {
    id: 'doodle',
    title: 'Doodle & Art',
    description: 'Creative patterns and artistic designs',
    icon: PenTool,
    color: 'teal',
    tools: [
      { id: 'pattern', title: 'Doodle Pattern', description: 'Generate seamless doodle patterns', path: '/doodle/pattern' },
      { id: 'quotes', title: 'Quotes + Doodle', description: 'Create inspiring quote designs', path: '/doodle/quotes' },
      { id: 'cursive', title: 'Cursive Art', description: 'Transform words into beautiful designs', path: '/doodle/cursive' },
      { id: 'decorative', title: 'Decorative Text', description: 'Design beautiful text arrangements', path: '/doodle/decorative' },
      { id: 'floral', title: 'Floral Patterns', description: 'Create beautiful floral designs', path: '/doodle/floral' },
      { id: 'anything', title: 'Doodle Anything', description: 'Create charming doodle patterns', path: '/doodle/anything' }
    ]
  },
  {
    id: 'content',
    title: 'Content Ideas',
    description: 'AI-powered coloring inspiration and planning',
    icon: Lightbulb,
    color: 'yellow',
    tools: [
      { id: 'coloring-ideas', title: 'Coloring Ideas', description: 'Generate creative coloring page themes', path: '/content/coloring-ideas' }
    ]
  }
];

function Dashboard() {
  const navigate = useNavigate();

  return (
    <CreditGuard>
      <div className="space-y-8">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">
            AI Coloring Page Generator
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Create beautiful, custom coloring pages in seconds with our AI-powered platform.
            Perfect for teachers, parents, and creative minds.
          </p>
        </div>

        <div className="space-y-8">
          {categories.map((category) => (
            <div key={category.id} className="space-y-4">
              <div className="flex items-center gap-3">
                <div className={`p-3 bg-${category.color}-50 rounded-lg`}>
                  <category.icon className={`w-6 h-6 text-${category.color}-500`} />
                </div>
                <div>
                  <h2 className="text-xl font-bold text-gray-900">{category.title}</h2>
                  <p className="text-gray-600">{category.description}</p>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {category.tools.map((tool) => (
                  <button
                    key={tool.id}
                    onClick={() => navigate(tool.path)}
                    className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 text-left"
                  >
                    <h3 className="text-lg font-semibold text-gray-900 mb-2">{tool.title}</h3>
                    <p className="text-gray-600">{tool.description}</p>
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </CreditGuard>
  );
}

export default Dashboard;
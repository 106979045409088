import React from 'react';
import { Wand2, Sparkles } from 'lucide-react';

export function LoadingSpinner() {
  return (
    <div className="flex flex-col items-center gap-6">
      {/* Magic Circle Container */}
      <div className="relative w-24 h-24">
        {/* Rotating Outer Ring */}
        <div className="absolute inset-0 rounded-full border-4 border-blue-100 animate-spin-slow" />
        
        {/* Pulsing Inner Circle */}
        <div className="absolute inset-2 rounded-full bg-gradient-to-tr from-blue-50 to-purple-50 animate-pulse" />
        
        {/* Central Wand */}
        <div className="absolute inset-0 flex items-center justify-center">
          <Wand2 className="w-8 h-8 text-blue-500 transform -rotate-45 animate-bounce-slow" />
        </div>

        {/* Floating Sparkles */}
        <div className="absolute inset-0">
          {[...Array(4)].map((_, i) => (
            <div
              key={i}
              className="absolute animate-float"
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animationDelay: `${i * 0.5}s`,
              }}
            >
              <Sparkles className="w-4 h-4 text-blue-400" />
            </div>
          ))}
        </div>
      </div>

      {/* Loading Text */}
      <div className="text-center space-y-1">
        <p className="text-lg font-medium text-gray-700">Creating Magic...</p>
        <p className="text-sm text-gray-500">Your coloring page is being generated ✨</p>
      </div>
    </div>
  );
}
import React, { useState } from 'react';
import { Palette } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { ColoringIdeasForm } from '../../components/content/ColoringIdeasForm';

function ColoringIdeas() {
  const [ideas, setIdeas] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Coloring Ideas Generator"
      description="Generate creative themes and concepts for coloring pages"
      icon={<Palette className="w-10 h-10 text-yellow-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <ColoringIdeasForm 
          onIdeasGenerated={setIdeas}
          setIsLoading={setIsLoading}
        />

        {isLoading ? (
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-600"></div>
          </div>
        ) : ideas.length > 0 && (
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Generated Coloring Ideas</h3>
            <ul className="space-y-2">
              {ideas.map((idea, index) => (
                <li key={index} className="flex items-center gap-2">
                  <span className="text-yellow-600 font-medium">{index + 1}.</span>
                  <span className="text-gray-700">{idea}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </PageLayout>
  );
}

export default ColoringIdeas;
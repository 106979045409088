import React from 'react';
import { Outlet } from 'react-router-dom';
import { CategoryLayout } from '../../components/layout/CategoryLayout';
import { Brain, Flower2, Sparkles, Globe2, UserCircle2, Users } from 'lucide-react';

const tools = [
  {
    id: 'mandala',
    title: 'Mandala Art',
    description: 'Create intricate mandala designs',
    icon: Flower2,
    path: 'mandala'
  },
  {
    id: 'tarot-cards',
    title: 'Tarot Cards',
    description: 'Design mystical tarot card illustrations',
    icon: Sparkles,
    path: 'tarot-cards'
  },
  {
    id: 'miniature-world',
    title: 'Miniature World',
    description: 'Create enchanting miniature scenes',
    icon: Globe2,
    path: 'miniature-world'
  },
  {
    id: 'mystery-characters',
    title: 'Mystery Characters',
    description: 'Design enigmatic character scenes',
    icon: UserCircle2,
    path: 'mystery-characters'
  },
  {
    id: 'quarrel-scene',
    title: 'Quarrel Scene',
    description: 'Create amusing disagreement scenes',
    icon: Users,
    path: 'quarrel-scene'
  }
];

function AdultLayout() {
  return (
    <CategoryLayout
      title="Adult Coloring"
      description="Complex and detailed designs for adults"
      icon={Brain}
      color="purple"
      tools={tools}
    >
      <Outlet />
    </CategoryLayout>
  );
}

export default AdultLayout;
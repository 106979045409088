import React, { useState } from 'react';
import { Briefcase } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface AnimalProfessionFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

export function AnimalProfessionForm({ onImageGenerated, setIsLoading }: AnimalProfessionFormProps) {
  const [animal, setAnimal] = useState('');
  const [profession, setProfession] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!animal.trim() || !profession.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `A charming, cartoon-style ${animal} character wearing a friendly ${profession} outfit, drawn in a simple coloring book style. The character has a cheerful smile and is standing in a welcoming pose. Use clean, bold outlines perfect for coloring. The design should be simple and child-friendly with minimal background details. Make it black and white line art with clear spaces for coloring. Include small props or tools related to their profession in a playful way.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="animal" className="block text-sm font-medium text-gray-700 mb-1">
              Choose an Animal
            </label>
            <input
              id="animal"
              type="text"
              value={animal}
              onChange={(e) => setAnimal(e.target.value)}
              placeholder="e.g., Fox, Penguin, Giraffe"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            />
          </div>
          <div>
            <label htmlFor="profession" className="block text-sm font-medium text-gray-700 mb-1">
              Choose a Profession
            </label>
            <input
              id="profession"
              type="text"
              value={profession}
              onChange={(e) => setProfession(e.target.value)}
              placeholder="e.g., Baker, Teacher, Artist"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              required
            />
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-orange-600 hover:bg-orange-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Briefcase className="w-5 h-5" />
          Generate Professional Animal
        </button>
      </form>
    </>
  );
}
import React, { useState } from 'react';
import { Type } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { DecorativeTextForm } from '../../components/DecorativeTextForm';
import { ImagePreview } from '../../components/ImagePreview';

function DecorativeText() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Decorative Text"
      description="Transform your words into beautiful sticker designs!"
      icon={<Type className="w-10 h-10 text-amber-500" />}
    >
      <div className="p-6 space-y-8">
        <DecorativeTextForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default DecorativeText;
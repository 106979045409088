import React, { useState } from 'react';
import { Copyright } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { CopyrightPageForm } from '../../components/content/CopyrightPageForm';

function CopyrightPage() {
  const [copyrightText, setCopyrightText] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Copyright Page Generator"
      description="Generate professional copyright pages for your books"
      icon={<Copyright className="w-10 h-10 text-yellow-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <CopyrightPageForm 
          onCopyrightGenerated={setCopyrightText}
          setIsLoading={setIsLoading}
        />

        {isLoading ? (
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-600"></div>
          </div>
        ) : copyrightText && (
          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Generated Copyright Page</h3>
            <div className="prose max-w-none">
              <div dangerouslySetInnerHTML={{ __html: copyrightText.replace(/\n/g, '<br>') }} />
            </div>
          </div>
        )}
      </div>
    </PageLayout>
  );
}

export default CopyrightPage;
import React, { useState } from 'react';
import { Quote } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface QuotesDoodleFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

export function QuotesDoodleForm({ onImageGenerated, setIsLoading }: QuotesDoodleFormProps) {
  const [quote, setQuote] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!quote.trim()) {
      toast.error('Please enter a quote');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Design a coloring book page featuring the quote "${quote}" with decorative doodle elements. The text should be in a beautiful, hand-drawn style with floral or geometric patterns surrounding it. Keep the design black and white with clear lines for coloring. Make it balanced and visually appealing.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="quote" className="block text-sm font-medium text-gray-700 mb-1">
            Enter your quote or message
          </label>
          <textarea
            id="quote"
            value={quote}
            onChange={(e) => setQuote(e.target.value)}
            placeholder="e.g., Dream big, Be kind, Stay positive"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-purple-500 min-h-[100px] resize-y"
            required
          />
          <p className="mt-1 text-sm text-gray-500">
            Keep it short and meaningful for the best results
          </p>
        </div>

        <button
          type="submit"
          className="w-full bg-purple-600 hover:bg-purple-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Quote className="w-5 h-5" />
          Generate Quote Design
        </button>
      </form>
    </>
  );
}
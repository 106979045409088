import React, { useState } from 'react';
import { Heart } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface CozyFriendsFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

export function CozyFriendsForm({ onImageGenerated, setIsLoading }: CozyFriendsFormProps) {
  const [firstAnimal, setFirstAnimal] = useState('');
  const [secondAnimal, setSecondAnimal] = useState('');
  const [activity, setActivity] = useState('');
  const [scene, setScene] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!firstAnimal.trim() || !secondAnimal.trim() || !activity.trim() || !scene.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `A ${firstAnimal} and ${secondAnimal} as best friends, happily and smiles in a cozy place. Simple, child-friendly outline art with ${activity} between characters. Large white spaces within designs for easy coloring. Warm and inviting scene with ${scene} as background outlines. Perfect for children's coloring book page. Ensure the image is in black and white with clear areas for coloring. Include the background with detailed small props or elements to support the situation.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="firstAnimal" className="block text-sm font-medium text-gray-700 mb-1">
              First Animal
            </label>
            <input
              id="firstAnimal"
              type="text"
              value={firstAnimal}
              onChange={(e) => setFirstAnimal(e.target.value)}
              placeholder="e.g., Penguin, Polar Bear"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-pink-500 focus:border-pink-500"
              required
            />
          </div>
          <div>
            <label htmlFor="secondAnimal" className="block text-sm font-medium text-gray-700 mb-1">
              Second Animal
            </label>
            <input
              id="secondAnimal"
              type="text"
              value={secondAnimal}
              onChange={(e) => setSecondAnimal(e.target.value)}
              placeholder="e.g., Reindeer, Arctic Fox"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-pink-500 focus:border-pink-500"
              required
            />
          </div>
          <div>
            <label htmlFor="activity" className="block text-sm font-medium text-gray-700 mb-1">
              What are they doing?
            </label>
            <input
              id="activity"
              type="text"
              value={activity}
              onChange={(e) => setActivity(e.target.value)}
              placeholder="e.g., sharing hot chocolate, playing games"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-pink-500 focus:border-pink-500"
              required
            />
          </div>
          <div>
            <label htmlFor="scene" className="block text-sm font-medium text-gray-700 mb-1">
              Background Scene
            </label>
            <input
              id="scene"
              type="text"
              value={scene}
              onChange={(e) => setScene(e.target.value)}
              placeholder="e.g., cozy fireplace, garden"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-pink-500 focus:border-pink-500"
              required
            />
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-pink-600 hover:bg-pink-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Heart className="w-5 h-5" />
          Generate Friendship Scene
        </button>
      </form>
    </>
  );
}
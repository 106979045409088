import React, { useState } from 'react';
import { BookOpen } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { AmazonDescriptionForm } from '../../components/content/AmazonDescriptionForm';

function AmazonDescription() {
  const [description, setDescription] = useState<{
    title: string;
    description: string;
    bulletPoints: string[];
    callToAction: string;
    keywords: string[];
    categories: string[];
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Amazon Description Generator"
      description="Generate optimized book descriptions for Amazon listings"
      icon={<BookOpen className="w-10 h-10 text-yellow-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <AmazonDescriptionForm 
          onDescriptionGenerated={setDescription}
          setIsLoading={setIsLoading}
        />

        {isLoading ? (
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-600"></div>
          </div>
        ) : description && (
          <div className="space-y-6">
            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Main Description</h3>
              <div className="prose max-w-none">
                <div dangerouslySetInnerHTML={{ __html: description.description }} />
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Key Features & Benefits</h3>
              <ul className="space-y-2">
                {description.bulletPoints.map((point, index) => (
                  <li key={index} className="flex items-start gap-2">
                    <span className="text-yellow-500 mt-1">•</span>
                    <span className="text-gray-700">{point.replace('• ', '')}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Call to Action</h3>
              <div className="prose max-w-none">
                <div dangerouslySetInnerHTML={{ __html: description.callToAction }} />
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Keywords</h3>
              <div className="space-y-4">
                <div>
                  <h4 className="font-medium text-gray-700 mb-2">Long-tail Keywords</h4>
                  <ul className="list-disc pl-5 space-y-1">
                    {description.keywords.slice(0, 7).map((keyword, index) => (
                      <li key={index} className="text-gray-600">{keyword}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h4 className="font-medium text-gray-700 mb-2">Short-tail Keywords</h4>
                  <ul className="list-disc pl-5 space-y-1">
                    {description.keywords.slice(7).map((keyword, index) => (
                      <li key={index} className="text-gray-600">{keyword}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Suggested Categories</h3>
              <ul className="list-disc pl-5 space-y-1">
                {description.categories.map((category, index) => (
                  <li key={index} className="text-gray-600">{category}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </PageLayout>
  );
}

export default AmazonDescription;
import React, { useState } from 'react';
import { Tent } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { TravelCampingForm } from '../../components/TravelCampingForm';
import { ImagePreview } from '../../components/ImagePreview';

function TravelCamping() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Travel & Camping"
      description="Create cozy outdoor adventure scenes for coloring!"
      icon={<Tent className="w-10 h-10 text-emerald-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <TravelCampingForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default TravelCamping;
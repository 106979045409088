import React, { useState } from 'react';
import { PenTool } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface CursiveArtFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

export function CursiveArtForm({ onImageGenerated, setIsLoading }: CursiveArtFormProps) {
  const [text, setText] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!text.trim()) {
      toast.error('Please enter some text');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a coloring book page featuring the words "${text}" in bold, flowing and cursive outline script. Use simple, clean lines for easy coloring. Include decorative elements in outline form, with ample space for coloring. The background should be plain to emphasize the outlined text and designs, allowing for creative coloring.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="text" className="block text-sm font-medium text-gray-700 mb-1">
            Enter your text for cursive design
          </label>
          <textarea
            id="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="e.g., Love, Dream Big, Be Kind"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-lime-500 focus:border-lime-500 min-h-[100px] resize-y"
            required
          />
          <p className="mt-1 text-sm text-gray-500">
            Short phrases work best for beautiful cursive designs
          </p>
        </div>

        <button
          type="submit"
          className="w-full bg-lime-600 hover:bg-lime-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <PenTool className="w-5 h-5" />
          Generate Cursive Design
        </button>
      </form>
    </>
  );
}
import React, { useState } from 'react';
import { Flower } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface FloralPatternsFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

export function FloralPatternsForm({ onImageGenerated, setIsLoading }: FloralPatternsFormProps) {
  const [subject, setSubject] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!subject.trim()) {
      toast.error('Please enter a subject');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `A ${subject} with floral patterns, drawn in a simple line style on a white background, without shadows or color. The subject are decorated with small flower designs that add charm. This design is perfect for kids aged three to four and can be used as illustrations for coloring pages or a sticker set, featuring clean lines, basic shapes, and vector art`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
            What would you like to decorate with flowers?
          </label>
          <input
            id="subject"
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="e.g., butterfly, heart, unicorn, star"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-red-400 focus:border-red-400"
            required
          />
          <p className="mt-1 text-sm text-gray-500">
            Try simple shapes or cute characters - they'll be adorned with lovely flowers!
          </p>
        </div>

        <button
          type="submit"
          className="w-full bg-red-400 hover:bg-red-500 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Flower className="w-5 h-5" />
          Generate Floral Design
        </button>
      </form>
    </>
  );
}
import React, { useState } from 'react';
import { Flower2 } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface MandalaArtFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

export function MandalaArtForm({ onImageGenerated, setIsLoading }: MandalaArtFormProps) {
  const [theme, setTheme] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!theme.trim()) {
      toast.error('Please enter a theme');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a detailed mandala coloring page with a ${theme} theme. The design should be intricate but clear, with bold black outlines on a white background. Include elements related to ${theme} woven into the mandala pattern. The artwork should be symmetrical and balanced, perfect for adult coloring. Ensure all lines are distinct and well-defined for easy coloring.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="theme" className="block text-sm font-medium text-gray-700 mb-1">
            Choose a theme for your mandala
          </label>
          <input
            id="theme"
            type="text"
            value={theme}
            onChange={(e) => setTheme(e.target.value)}
            placeholder="e.g., ocean waves, lotus flowers, celestial bodies"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            required
          />
          <p className="mt-1 text-sm text-gray-500">
            Try nature elements, geometric patterns, or spiritual symbols
          </p>
        </div>

        <button
          type="submit"
          className="w-full bg-purple-600 hover:bg-purple-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Flower2 className="w-5 h-5" />
          Generate Mandala Design
        </button>
      </form>
    </>
  );
}
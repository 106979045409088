import React, { useState } from 'react';
import { Car } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface HappyVehiclesFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

export function HappyVehiclesForm({ onImageGenerated, setIsLoading }: HappyVehiclesFormProps) {
  const [subject, setSubject] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!subject.trim()) {
      toast.error('Please enter a vehicle type');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a black-and-white illustration for a children's coloring book. The illustration should feature a friendly, cartoonish ${subject} with a smiling face. The vehicle should have large, expressive eyes and a simple, cute design. Surround the vehicle with a minimal background that suits its environment (e.g., clouds and sky for an airplane, a road for a car, beach or sea for a boats). Ensure the style is consistent with a children's coloring book, keeping lines bold and clear for easy coloring.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
            Which vehicle would you like to make cute?
          </label>
          <input
            id="subject"
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="e.g., airplane, train, submarine, rocket"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500"
            required
          />
          <p className="mt-1 text-sm text-gray-500">
            Try any vehicle - it will become an adorable character!
          </p>
        </div>

        <button
          type="submit"
          className="w-full bg-cyan-600 hover:bg-cyan-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Car className="w-5 h-5" />
          Generate Happy Vehicle
        </button>
      </form>
    </>
  );
}
import React, { useState } from 'react';
import { Ghost } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface MonsterFunFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

export function MonsterFunForm({ onImageGenerated, setIsLoading }: MonsterFunFormProps) {
  const [activity, setActivity] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!activity.trim()) {
      toast.error('Please enter an activity');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a whimsical illustration for a coloring page featuring a random strange monster, in a playful setting with ${activity}. The design should be in black-and-white line art, suitable for coloring, with clear outlines and fun details.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="activity" className="block text-sm font-medium text-gray-700 mb-1">
            What is the monster doing?
          </label>
          <input
            id="activity"
            type="text"
            value={activity}
            onChange={(e) => setActivity(e.target.value)}
            placeholder="e.g., playing basketball, baking cookies, flying a kite"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-fuchsia-500 focus:border-fuchsia-500"
            required
          />
          <p className="mt-1 text-sm text-gray-500">
            Try any fun activity - our monster will make it silly!
          </p>
        </div>

        <button
          type="submit"
          className="w-full bg-fuchsia-600 hover:bg-fuchsia-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Ghost className="w-5 h-5" />
          Generate Monster Scene
        </button>
      </form>
    </>
  );
}
import React, { useState } from 'react';
import { Building2 } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { RoomCornerForm } from '../../components/RoomCornerForm';
import { ImagePreview } from '../../components/ImagePreview';

function RoomCorner() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Room Corner"
      description="Create isometric room corner designs for coloring!"
      icon={<Building2 className="w-10 h-10 text-blue-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <RoomCornerForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default RoomCorner;
import React, { useState } from 'react';
import { Zap } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface SuperheroNameFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

export function SuperheroNameForm({ onImageGenerated, setIsLoading }: SuperheroNameFormProps) {
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!name.trim() || !gender) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a coloring page featuring a cute ${gender.toLowerCase()} superhero character in a simple, cartoonish style. The superhero should be in any superhero pose with a friendly expression, performing a HEROIC action. Include the name '${name}' in the background, in a large, bold, and clear line art font that is easy to color, with hollow letters for easy filling. Use a background with any random superhero-related things. Ensure all elements are well-balanced and easily recognizable. The design should be line art, suitable for coloring.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
              Child's Name
            </label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter name"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
              required
            />
          </div>
          <div>
            <label htmlFor="gender" className="block text-sm font-medium text-gray-700 mb-1">
              Superhero Gender
            </label>
            <select
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
              required
            >
              <option value="">Select gender</option>
              <option value="Boy">Boy</option>
              <option value="Girl">Girl</option>
            </select>
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Zap className="w-5 h-5" />
          Generate Superhero Design
        </button>
      </form>
    </>
  );
}
import React from 'react';
import { CreditCard, ExternalLink, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function PaymentPage() {
  const navigate = useNavigate();
  const successUrl = 'https://app.coolor.ing/#/payment/success';

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4">
      <div className="max-w-3xl mx-auto">
        <div className="mb-8">
          <button
            onClick={() => navigate('/')}
            className="flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-6"
          >
            <ArrowLeft className="w-5 h-5" />
            Back to Dashboard
          </button>

          <div className="text-center">
            <h1 className="text-3xl font-bold text-gray-900">Top Up Credits</h1>
            <p className="mt-2 text-gray-600">Purchase additional credits for your account</p>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          <div className="p-6">
            <div className="flex items-center gap-3 mb-6">
              <CreditCard className="w-8 h-8 text-blue-600" />
              <h2 className="text-xl font-semibold text-gray-900">Credit Package</h2>
            </div>

            <div className="space-y-6">
              <div className="flex items-center justify-between p-4 bg-blue-50 rounded-lg">
                <div>
                  <h3 className="text-lg font-medium text-gray-900">150 Credits</h3>
                  <p className="text-sm text-gray-600">One-time purchase</p>
                </div>
                <div className="text-right">
                  <div className="text-2xl font-bold text-blue-600">RM55</div>
                  <a
                    href={`https://pay.bcl.my/form/TopupKredit-150?success_url=${encodeURIComponent(successUrl)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-1 text-sm text-blue-600 hover:text-blue-800 mt-1"
                  >
                    Purchase <ExternalLink className="w-4 h-4" />
                  </a>
                </div>
              </div>

              <div className="border-t pt-4">
                <h4 className="text-sm font-medium text-gray-700 mb-2">Important Information:</h4>
                <ul className="text-sm text-gray-600 space-y-1">
                  <li>• Credits will be added automatically after successful payment</li>
                  <li>• Payment confirmation may take a few minutes to process</li>
                  <li>• For any issues, please contact our support team</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
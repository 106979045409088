import React from 'react';
import { Wand2 } from 'lucide-react';

export function LoadingOverlay() {
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-xl shadow-lg flex flex-col items-center gap-4">
        <div className="relative">
          <div className="w-16 h-16 border-4 border-blue-100 rounded-full animate-spin">
            <div className="absolute inset-0 flex items-center justify-center">
              <Wand2 className="w-8 h-8 text-blue-500 transform -rotate-45 animate-pulse" />
            </div>
          </div>
        </div>
        <p className="text-gray-700 font-medium">Generating...</p>
      </div>
    </div>
  );
}
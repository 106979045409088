import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './lib/firebase';
import Dashboard from './pages/Dashboard';
import { LoginPage } from './pages/LoginPage';
import { SignupPage } from './pages/SignupPage';
import { AccountSettings } from './pages/AccountSettings';
import { AdminRoute } from './components/AdminRoute';
import { UnauthorizedPage } from './pages/UnauthorizedPage';
import { DashboardLayout } from './components/layout/DashboardLayout';
import AdminPortal from './pages/admin/AdminPortal';
import UserManagement from './pages/admin/UserManagement';
import SystemSettings from './pages/admin/SystemSettings';
import CreditManagement from './pages/admin/CreditManagement';
import PaymentPage from './pages/PaymentPage';
import PaymentSuccess from './pages/PaymentSuccess';
import ContentLayout from './pages/content/ContentLayout';

// Import all layouts
import KidsLayout from './pages/kids/KidsLayout';
import AdultLayout from './pages/adult/AdultLayout';
import CozyLayout from './pages/cozy/CozyLayout';
import DoodleLayout from './pages/doodle/DoodleLayout';

// Import Content Ideas pages
import ColoringIdeas from './pages/content/ColoringIdeas';
import AmazonDescription from './pages/content/AmazonDescription';
import CopyrightPage from './pages/content/CopyrightPage';

// Import all pages
import SimpleEasy from './pages/kids/SimpleEasy';
import SuperheroName from './pages/kids/SuperheroName';
import MonsterFun from './pages/kids/MonsterFun';
import HappyVehicles from './pages/kids/HappyVehicles';
import FoodColoring from './pages/kids/FoodColoring';
import CircusClowns from './pages/kids/CircusClowns';
import FlyingBirds from './pages/kids/FlyingBirds';

import MandalaArt from './pages/adult/MandalaArt';
import TarotCards from './pages/adult/TarotCards';
import MiniatureWorld from './pages/adult/MiniatureWorld';
import MysteryCharacters from './pages/adult/MysteryCharacters';
import QuarrelScene from './pages/adult/QuarrelScene';

import CozyFriends from './pages/cozy/CozyFriends';
import CozyBuilding from './pages/cozy/CozyBuilding';
import TravelCamping from './pages/cozy/TravelCamping';
import FunnyShop from './pages/cozy/FunnyShop';
import AnimalProfession from './pages/cozy/AnimalProfession';
import RoomCorner from './pages/cozy/RoomCorner';

import DoodlePattern from './pages/doodle/DoodlePattern';
import QuotesDoodle from './pages/doodle/QuotesDoodle';
import CursiveArt from './pages/doodle/CursiveArt';
import DecorativeText from './pages/doodle/DecorativeText';
import FloralPatterns from './pages/doodle/FloralPatterns';
import DoodleAnything from './pages/doodle/DoodleAnything';

export function App() {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup/one-time" element={<SignupPage type="one-time" />} />
        <Route path="/signup/subscription" element={<SignupPage type="subscription" />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/payment/success" element={<PaymentSuccess />} />

        {/* Protected Routes */}
        <Route element={<DashboardLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="account" element={<AccountSettings />} />
          
          {/* Kids Routes */}
          <Route path="kids" element={<KidsLayout />}>
            <Route path="simple" element={<SimpleEasy />} />
            <Route path="superhero-name" element={<SuperheroName />} />
            <Route path="monster-fun" element={<MonsterFun />} />
            <Route path="happy-vehicles" element={<HappyVehicles />} />
            <Route path="food-coloring" element={<FoodColoring />} />
            <Route path="circus-clowns" element={<CircusClowns />} />
            <Route path="flying-birds" element={<FlyingBirds />} />
          </Route>

          {/* Adult Routes */}
          <Route path="adult" element={<AdultLayout />}>
            <Route path="mandala" element={<MandalaArt />} />
            <Route path="tarot-cards" element={<TarotCards />} />
            <Route path="miniature-world" element={<MiniatureWorld />} />
            <Route path="mystery-characters" element={<MysteryCharacters />} />
            <Route path="quarrel-scene" element={<QuarrelScene />} />
          </Route>

          {/* Cozy Routes */}
          <Route path="cozy" element={<CozyLayout />}>
            <Route path="friends" element={<CozyFriends />} />
            <Route path="building" element={<CozyBuilding />} />
            <Route path="travel-camping" element={<TravelCamping />} />
            <Route path="funny-shop" element={<FunnyShop />} />
            <Route path="animal-profession" element={<AnimalProfession />} />
            <Route path="room-corner" element={<RoomCorner />} />
          </Route>

          {/* Doodle Routes */}
          <Route path="doodle" element={<DoodleLayout />}>
            <Route path="pattern" element={<DoodlePattern />} />
            <Route path="quotes" element={<QuotesDoodle />} />
            <Route path="cursive" element={<CursiveArt />} />
            <Route path="decorative" element={<DecorativeText />} />
            <Route path="floral" element={<FloralPatterns />} />
            <Route path="anything" element={<DoodleAnything />} />
          </Route>

          {/* Content Ideas Routes */}
          <Route path="content" element={<ContentLayout />}>
            <Route path="coloring-ideas" element={<ColoringIdeas />} />
            <Route path="amazon-description" element={<AmazonDescription />} />
            <Route path="copyright-page" element={<CopyrightPage />} />
          </Route>

          {/* Admin Routes */}
          <Route path="admin" element={<AdminRoute><AdminPortal /></AdminRoute>} />
          <Route path="admin/users" element={<AdminRoute><UserManagement /></AdminRoute>} />
          <Route path="admin/settings" element={<AdminRoute><SystemSettings /></AdminRoute>} />
          <Route path="admin/credits" element={<AdminRoute><CreditManagement /></AdminRoute>} />
        </Route>

        {/* Catch all route */}
        <Route path="*" element={<Navigate to={user ? "/" : "/unauthorized"} replace />} />
      </Routes>
      <Toaster position="top-center" />
    </>
  );
}

export default App;
import React, { useState } from 'react';
import { Palette } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { SimpleEasyForm } from '../../components/SimpleEasyForm';
import { ImagePreview } from '../../components/ImagePreview';

function SimpleEasy() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Simple & Easy"
      description="Create simple coloring pages in seconds!"
      icon={<Palette className="w-10 h-10 text-blue-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <SimpleEasyForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default SimpleEasy;
import React, { useState } from 'react';
import { Upload, Download, Loader } from 'lucide-react';
import toast from 'react-hot-toast';
import Papa from 'papaparse';

interface CsvUploadProps {
  onSuccess?: () => void;
}

export function CsvUpload({ onSuccess }: CsvUploadProps) {
  const [isUploading, setIsUploading] = useState(false);

  const validateCsvData = (data: any[]) => {
    const requiredFields = ['email', 'password'];
    return data.every(row => 
      requiredFields.every(field => row[field] && typeof row[field] === 'string')
    );
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.type !== 'text/csv') {
      toast.error('Please upload a CSV file');
      return;
    }

    setIsUploading(true);

    try {
      // Parse CSV file
      const text = await file.text();
      const { data } = Papa.parse(text, {
        header: true,
        skipEmptyLines: true,
      });

      if (!validateCsvData(data)) {
        throw new Error('CSV file must contain email and password columns');
      }

      const response = await fetch('/.netlify/functions/bulk-create-users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ users: data }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to process users');
      }

      const { results } = await response.json();

      if (results.successful.length > 0) {
        toast.success(`Successfully created ${results.successful.length} users`);
      }

      if (results.failed.length > 0) {
        toast.error(`Failed to create ${results.failed.length} users`);
        console.error('Failed uploads:', results.failed);
      }

      onSuccess?.();
      event.target.value = '';

    } catch (error) {
      console.error('Upload error:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to process CSV file');
    } finally {
      setIsUploading(false);
    }
  };

  const downloadTemplate = () => {
    const csvContent = 'email,password\nuser@example.com,StrongPass123!';
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'users-template.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-lg font-medium text-gray-900">Bulk Upload Users</h3>
          <p className="text-sm text-gray-500">Upload multiple users via CSV file</p>
        </div>
        <button
          onClick={downloadTemplate}
          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        >
          <Download className="w-4 h-4 mr-2" />
          Download Template
        </button>
      </div>

      <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <div className="space-y-1 text-center">
          <Upload className="mx-auto h-12 w-12 text-gray-400" />
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor="csv-upload"
              className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
            >
              <span>Upload a file</span>
              <input
                id="csv-upload"
                name="csv-upload"
                type="file"
                accept=".csv"
                className="sr-only"
                onChange={handleFileUpload}
                disabled={isUploading}
              />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-500">CSV files only</p>
        </div>
      </div>

      {isUploading && (
        <div className="flex items-center justify-center">
          <Loader className="w-5 h-5 animate-spin text-blue-600" />
          <span className="ml-2 text-sm text-gray-600">Processing upload...</span>
        </div>
      )}
    </div>
  );
}
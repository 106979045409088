import React, { useState } from 'react';
import { Users } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { QuarrelSceneForm } from '../../components/QuarrelSceneForm';
import { ImagePreview } from '../../components/ImagePreview';

function QuarrelScene() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Quarrel Scene"
      description="Create amusing disagreement scenes for coloring!"
      icon={<Users className="w-10 h-10 text-orange-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <QuarrelSceneForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default QuarrelScene;
import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../../lib/firebase';
import { UserCircle, Shield, Trash2, Lock } from 'lucide-react';
import toast from 'react-hot-toast';

interface User {
  id: string;
  email: string;
  displayName?: string;
  role: string;
  creditsAvailable: number;
}

export function UserList() {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSendingReset, setIsSendingReset] = useState(false);
  const [isUpdatingRole, setIsUpdatingRole] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'users'),
      (snapshot) => {
        const userData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as User[];
        setUsers(userData);
      },
      (error) => {
        console.error('Error fetching users:', error);
        toast.error('Failed to load users');
      }
    );

    return () => unsubscribe();
  }, []);

  const handleUpdateRole = async (userId: string, newRole: string) => {
    const user = users.find(u => u.id === userId);
    if (!user) return;

    const previousRole = user.role;
    setIsUpdatingRole(userId);

    try {
      // Optimistically update the UI
      setUsers(prevUsers =>
        prevUsers.map(u =>
          u.id === userId ? { ...u, role: newRole } : u
        )
      );

      const response = await fetch('/.netlify/functions/update-user-role', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-user-email': auth.currentUser?.email || 'admin'
        },
        body: JSON.stringify({
          userId,
          role: newRole,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to update user role');
      }

      // Update local state with server response
      setUsers(prevUsers =>
        prevUsers.map(u =>
          u.id === userId ? { ...u, ...data.updates } : u
        )
      );

      toast.success('User role updated successfully');
    } catch (error) {
      console.error('Error updating role:', error);
      
      // Revert the optimistic update
      setUsers(prevUsers =>
        prevUsers.map(u =>
          u.id === userId ? { ...u, role: previousRole } : u
        )
      );
      
      toast.error(error instanceof Error ? error.message : 'Failed to update user role');
    } finally {
      setIsUpdatingRole(null);
    }
  };

  const handleDeleteUser = async (userId: string, email: string) => {
    if (!confirm(`Are you sure you want to delete user ${email}?`)) {
      return;
    }

    setIsDeleting(true);
    try {
      const response = await fetch('/.netlify/functions/delete-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete user');
      }

      await deleteDoc(doc(db, 'users', userId));
      setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
      setSelectedUsers(prev => prev.filter(id => id !== userId));
      
      toast.success('User deleted successfully');
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to delete user');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSendPasswordReset = async (email: string) => {
    setIsSendingReset(true);
    try {
      const response = await fetch('/.netlify/functions/send-password-reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to send password reset');
      }

      toast.success('Password reset email sent successfully');
    } catch (error) {
      console.error('Error sending password reset:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to send password reset');
    } finally {
      setIsSendingReset(false);
    }
  };

  return (
    <div className="space-y-4">
      {selectedUsers.length > 0 && (
        <div className="bg-blue-50 p-4 rounded-lg flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Shield className="w-5 h-5 text-blue-600" />
            <span className="text-blue-700">
              {selectedUsers.length} users selected
            </span>
          </div>
          <div className="flex gap-2">
            <button
              onClick={handleBulkDelete}
              disabled={isDeleting}
              className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 disabled:opacity-50"
            >
              {isDeleting ? 'Deleting...' : 'Delete Selected'}
            </button>
          </div>
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left">
                <input
                  type="checkbox"
                  checked={selectedUsers.length === users.length}
                  onChange={() => {
                    setSelectedUsers(
                      selectedUsers.length === users.length ? [] : users.map(u => u.id)
                    );
                  }}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                User
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Role
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {users.map((user) => (
              <tr key={user.id}>
                <td className="px-6 py-4">
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(user.id)}
                    onChange={() => {
                      setSelectedUsers(prev =>
                        prev.includes(user.id)
                          ? prev.filter(id => id !== user.id)
                          : [...prev, user.id]
                      );
                    }}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <UserCircle className="h-8 w-8 text-gray-400" />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {user.displayName || 'No Name'}
                      </div>
                      <div className="text-sm text-gray-500">{user.email}</div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <select
                    value={user.role}
                    onChange={(e) => handleUpdateRole(user.id, e.target.value)}
                    disabled={isUpdatingRole === user.id}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:bg-gray-100"
                  >
                    <option value="one-time-credit">One Time Credit (300 credits)</option>
                    <option value="subscription">Subscription (250 credits/month)</option>
                    <option value="unlimited">Unlimited</option>
                  </select>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => handleSendPasswordReset(user.email)}
                      disabled={isSendingReset}
                      className="text-blue-600 hover:text-blue-900 disabled:opacity-50"
                      title="Send password reset email"
                    >
                      <Lock className="w-5 h-5" />
                    </button>
                    <button
                      onClick={() => handleDeleteUser(user.id, user.email)}
                      disabled={isDeleting}
                      className="text-red-600 hover:text-red-900 disabled:opacity-50"
                      title="Delete user"
                    >
                      <Trash2 className="w-5 h-5" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../lib/firebase';
import { Navigate } from 'react-router-dom';
import { UserList } from '../../components/admin/UserList';
import { AddUserForm } from '../../components/admin/AddUserForm';
import { CsvUpload } from '../../components/admin/CsvUpload';

export default function UserManagement() {
  const [user] = useAuthState(auth);

  if (!user || user.email !== 'qimi.work@gmail.com') {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="space-y-8">
          <div>
            <h1 className="text-3xl font-bold text-gray-900">User Management</h1>
            <p className="mt-2 text-gray-600">Manage users, credits, and roles</p>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Add New User</h2>
            <AddUserForm />
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Bulk Upload Users</h2>
            <CsvUpload />
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">User List</h2>
            <UserList />
          </div>
        </div>
      </div>
    </div>
  );
}
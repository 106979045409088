import React, { useState } from 'react';
import { Building2 } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface CozyBuildingFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const THEMES = [
  { id: 'japanese', label: 'Japanese' },
  { id: 'medieval', label: 'Medieval' },
  { id: 'modern', label: 'Modern' },
  { id: 'colonial', label: 'Colonial' },
  { id: 'muji', label: 'MUJI' },
  { id: 'christmas', label: 'Christmas' },
  { id: 'space', label: 'Space Theme' }
] as const;

export function CozyBuildingForm({ onImageGenerated, setIsLoading }: CozyBuildingFormProps) {
  const [building, setBuilding] = useState('');
  const [theme, setTheme] = useState<string>(THEMES[0].id);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!building.trim()) {
      toast.error('Please enter a building type');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const selectedTheme = THEMES.find(t => t.id === theme)?.label || theme;
      const prompt = `A SIMPLE COZY CHILDLIKE DRAW OF ISOMETRIC LINE DRAW FEATURE ${building}, CHILD'S ART, SIMPLE STYLE, ISOMETRIC 3D, UNCOLORED ILLUSTRATION, FOR COLORING BOOK PAGES, LOW POLY, ISOMETRIC ART, SIMPLE, CHILD LINE ART, CONCEPT ART, BASIC BLACK LINES, WHITE BACKGROUND, THIN OUTLINE. Must Use ${selectedTheme} Theme.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div className="space-y-4">
          <div>
            <label htmlFor="building" className="block text-sm font-medium text-gray-700 mb-1">
              What building would you like to design?
            </label>
            <input
              id="building"
              type="text"
              value={building}
              onChange={(e) => setBuilding(e.target.value)}
              placeholder="e.g., Castle, House, Temple, Space Station"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              required
            />
          </div>
          <div>
            <label htmlFor="theme" className="block text-sm font-medium text-gray-700 mb-1">
              Choose a Theme
            </label>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
              {THEMES.map((themeOption) => (
                <button
                  key={themeOption.id}
                  type="button"
                  onClick={() => setTheme(themeOption.id)}
                  className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                    theme === themeOption.id
                      ? 'bg-indigo-100 text-indigo-700 border-2 border-indigo-500'
                      : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                  }`}
                >
                  {themeOption.label}
                </button>
              ))}
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Building2 className="w-5 h-5" />
          Generate {theme === 'christmas' ? 'Festive' : theme.charAt(0).toUpperCase() + theme.slice(1)} Building
        </button>
      </form>
    </>
  );
}